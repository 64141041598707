<template>
    <div class="home-wrapper">
        <div class="home-body">
            <div class="title">超省钱购物神器</div>
            <div class="title">分享还可赚收益</div>
            <img src="../assets/mobile/home/home.png" class="home-img"/>
        </div>
        <div class="home-footer">
            <div :class="isIOS ? 'mobile-block mobile-block-selected' : 'mobile-block mobile-block-notselected'" @click="handleChangeMobile('ios')">
                <img :src="isIOS ? require('../assets/pc/home/IOS_red.png') : require('../assets/pc/home/IOS_white.png')" class="mobile-img"/>
                iPhone版下载
            </div>
            <div :class="isIOS ? 'mobile-block mobile-block-notselected' : 'mobile-block mobile-block-selected'" @click="handleChangeMobile('android')">
                <img :src="isIOS ? require('../assets/pc/home/android_white.png') : require('../assets/pc/home/android_red.png')" class="mobile-img"/>
                Android版下载
            </div>
        </div>
    </div>
</template>

<script>
    import {getVersionLink} from '@/api/requestApi.js'
    export default {
        name: "home",
        data(){
            return {
                isIOS: true,
                iosLink: '',
                androidLink: ''
            }
        },
        mounted(){
            this.getLink();
        },
        methods:{
            getLink(){
                getVersionLink().then((data)=>{
                    this.iosLink = data.iosLink;
                    this.androidLink = data.androidLink;
                })
            },
            handleChangeMobile(val){
                if(val === 'android'){
                    this.isIOS = false;
                    window.location.href = 'https://mall.ujtao.com/#/download';
                }else{
                    this.isIOS = true;
                    window.location.href = 'https://itunes.apple.com/cn/app/apple-store/id1572797930';
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @mixin width-height($width: 100%, $height: 100%){
        width: $width;
        height: $height;
    }
    @mixin font($size: 58px,$color:#fff,$weight:600){
        font-size: $size;
        color: $color;
        font-weight: $weight;
    }
    @mixin flex($ai:center,$jc:center){
        display: flex;
        align-items: $ai;
        justify-content: $jc;
    }
    .home-wrapper{
        @include width-height;
        display: flex;
        flex-direction: column;

        .home-body{
            flex: 1;
            background-image: url('../assets/mobile/home/bg.png');
            background-size:100% 100%;
            padding-top: 50px;
            position: relative;
            z-index: -2;

            .title{
                @include font;
                text-align: center;
            }
            .home-img{
                @include width-height(690px,1500px);
                max-height: 100%;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: -1;
            }
        }
        .home-footer{
            background: #fc4e72;
            padding: 30px 0;
            @include flex(center, space-between);
            flex-direction: column;

            .mobile-block{
                @include width-height(330px,80px);
                font-size: 32px;
                font-weight: 500;
                border-radius: 40px;
                margin-bottom: 20px;
                @include flex;
                cursor: pointer;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .mobile-block-selected{
                border: none;
                color: #FA0655;
                background: #ffffff;
            }
            .mobile-block-notselected{
                border: 1px solid #ffffff;
                color:#ffffff;
                background: transparent;
            }
            .mobile-img{
                @include width-height(34px,40p);
                margin-right: 10px;
            }
        }
    }
</style>
