<template>
    <div class="wrapper">
        <navbar @changeNav="handleChangeNav" :currentIndex="currentIndex"></navbar>
        <van-swipe vertical :loop="false" :show-indicators="false" ref="swipe" class="swipe" @change="handleChangeSwipe">
            <van-swipe-item class="swipe-item"><home></home></van-swipe-item>

            <van-swipe-item v-for="(item,index) in introduceList" :key="index" class="introduce-swipe-item">
                <img :src="item.imgSrc" class="img"/>
                <div class="text-block">
                    <div class="title">{{item.title}}</div>
                    <div class="content">{{item.content}}</div>
                </div>
            </van-swipe-item>

            <van-swipe-item class="swipe-item"><about></about></van-swipe-item>

            <van-swipe-item class="relate-swipe-item">
                <div class="block">
                    <div class="title">联系我们</div>
                    <div class="info-content">
                        <div v-for="(item,index) in relateLlist" :key="index" class="info-block">
                            <img :src="item.imgSrc" class="info-img"/>
                            <div class="info-text">
                                <div class="info-title">{{item.title}}</div>
                                <div>{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item class="relate-swipe-item">
               <div class="block">
                   <div class="title">给我们留言</div>
                   <div class="info-form-content">
                       <div>
                           <van-field v-model="form.name" placeholder="您的名字" maxlength="10" class="inp"/>
                           <van-field v-model="form.phoneNumber" placeholder="联系方式" maxlength="20" class="inp"/>
                           <van-field v-model="form.content" placeholder="您的意见" maxlength="200" type="textarea" rows="3"/>
                       </div>
                       <van-button class="btn" @click="handleSubmit">发送</van-button>
                   </div>
                   <record color="#999999" v-if="isShowFooter"></record>
               </div>
            </van-swipe-item>
        </van-swipe>
    </div>
</template>

<script>
    import navbar from "./navbar";
    import home from './home'
    import about from './about'
    import record from "./record";

    import {saveMessage} from '@/api/requestApi.js'

    import Vue from 'vue';
    import { Toast } from 'vant';
    Vue.use(Toast);
    export default {
        name: "index",
        components:{
            navbar,
            home,
            about,
            record
        },
        data(){
            return {
                currentIndex: 0,
                introduceList:[
                    {
                        imgSrc: require('../assets/mobile/introduce/coupon.png'),
                        title: '超级神券',
                        content: '我们不生产优惠券，我们是优惠券的搬运工。优品淘金每日更新海量大额优惠券，全网百万优惠池一网打尽，让你天天都是双十一！解决你所有烦恼，真正让你省钱、省时、省心！'
                    },
                    {
                        imgSrc: require('../assets/mobile/introduce/share.png'),
                        title: '分享获利',
                        content: '用户无论自购还是分享给他人，都可以获利，让你利益最大化。通过分享这些优惠链接，别人通过您分享的链接下单，就得到相应的报酬啦。'
                    },
                    {
                        imgSrc: require('../assets/mobile/introduce/withdraw.png'),
                        title: '收益提现',
                        content: '除了购物，用户还可以通过完成每日任务、签到等方式轻松获得收益，收益更可以随时提现。别人网购吃土，自己网购省赚，不限空间时间，碎片时间赚个钱；真正为您实现省钱赚钱两不误。'
                    },
                    {
                        imgSrc: require('../assets/mobile/introduce/platform.png'),
                        title: '多平台合作',
                        content: '目前除了已对接淘宝、京东、拼多多等各大平台外，还有美团饿了么点外卖、飞猪出门旅游订酒店买机票，衣食住行吃喝玩乐全部覆盖，真是全面实惠又贴心！'
                    }
                ],
                relateLlist:[
                    {
                        imgSrc: require('../assets/pc/relate/address.png'),
                        title:'公司地址',
                        content:'广州市天河区大观南路36号'
                    },
                    {
                        imgSrc: require('../assets/pc/relate/email.png'),
                        title:'电子邮箱',
                        content:'admin@ujtao.com'
                    },
                    {
                        imgSrc: require('../assets/pc/relate/qq.png'),
                        title:'QQ',
                        content:'2448584757'
                    }
                ],
                form: {
                    channel: 1, //留言渠道：1官网 2安卓 3IOS
                    name: '',
                    phoneNumber: '',
                    content: ''
                },
                isShowFooter:true,
                docmHeight: document.documentElement.clientHeight,  //默认屏幕高度
                showHeight: document.documentElement.clientHeight,   //实时屏幕高度
            }
        },
        watch:{
            showHeight() {
                if(this.docmHeight > this.showHeight){
                    this.isShowFooter = false
                }else{
                    this.isShowFooter = true
                }
            }
        },
        mounted() {
            document.getElementsByTagName('body')[0].style.height=`${document.documentElement.clientHeight}px`;

            // window.onresize监听页面高度的变化
            window.onresize = ()=>{
                return(()=>{
                    this.showHeight = document.body.clientHeight;
                })()
            }
        },
        methods:{
            //切换导航栏，滑动swipe==>会触发handleChangeSwipe
            handleChangeNav(index){
                if(index === 2){
                    this.$refs.swipe.swipeTo(5,{
                        immediate: true
                    });
                    return false;
                }
                if(index === 3){
                    this.$refs.swipe.swipeTo(6,{
                        immediate: true
                    });
                    return false;
                }

                this.$refs.swipe.swipeTo(index,{
                    immediate: true
                });
            },
            //滑动swipe，更新路由hash，切换选中的导航栏
            handleChangeSwipe(index){
                switch(index){
                    case 0:
                        window.location.hash = '#/home';
                        this.currentIndex = 0;
                        break;
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        window.location.hash = '#/introduce';
                        this.currentIndex = 1;
                        break;
                    case 5:
                        window.location.hash = '#/about';
                        this.currentIndex = 2;
                        break;
                    case 6:
                    case 7:
                        window.location.hash = '#/relate';
                        this.currentIndex = 3;
                        break;
                }
            },
            handleSubmit(){
                if(!this.form.name || !this.form.phoneNumber || !this.form.content){
                    Toast('请填写完整信息');
                    return false;
                }

                saveMessage(this.form).then(() => {
                    Toast('感谢您的留言');
                    this.form = {};
                })
            }
        }
    }
</script>

<style>
    .van-cell{
        background: #ffffff;
        border: 1px solid #d8d8d8;
    }
</style>
<style scoped lang="scss">
    @mixin width-height($width: 100%, $height: 100%){
        width: $width;
        height: $height;
    }
    @mixin font($size: 54px,$color:#E63053,$weight:600){
        font-size: $size;
        color: $color;
        font-weight: $weight;
    }
    @mixin flex{
        display: flex;
        flex-direction: column;
    }
    .wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .swipe{
            flex: 1;

            .introduce-swipe-item{
                @include width-height;
                background-image: url('../assets/mobile/introduce/bg.png');
                background-size:100% 100%;

                .img{
                    @include width-height(100%,1000px);
                }
                .text-block{
                    padding: 0 100px;
                    position: relative;
                    z-index: 9999;
                    top: -280px;

                    .title{
                        @include font;
                        text-align: center;
                        margin-bottom: 50px;
                    }
                    .content{
                        @include font(32px,#1F1F1F,400);
                        line-height: 55px;
                    }
                }
            }

            .relate-swipe-item{
                @include width-height;
                background-image: url('../assets/mobile/relate/bg.png');
                background-size:100% 100%;

                .block{
                    height: 100%;
                    padding: 150px 70px 200px;
                    box-sizing: border-box;
                    @include flex;

                    .title{
                        @include font(48px,#1F1F1F,500);
                        text-align: center;
                        margin-bottom: 120px;
                    }
                    .info-content{
                        flex: 1;
                    }
                    .info-form-content{
                        flex: 1;
                        @include flex;
                        justify-content: space-between;
                    }
                    .info-block{
                        display: flex;
                        margin-bottom: 90px;
                        &:last-child{
                            margin-bottom: 0;
                        }

                        .info-img{
                            @include width-height(134px,134px);
                            flex-shrink: 0;
                            margin-right: 50px;
                        }
                        .info-text{
                            @include font(24px,#5B5B5B,400);
                            padding: 10px 0;
                            @include flex;
                            justify-content: space-between;

                            .info-title{
                                @include font(32px,#1F1F1F,500);
                            }
                        }
                    }

                    .inp{
                        margin-bottom: 30px;
                    }
                    .btn{
                        background: #e63176;
                        @include font(32px,#ffffff,500);
                    }
                }
            }
        }
    }
</style>
